import React from "react"
import { ToastContainer } from "react-toastify"
import brochure from "../pdfs/Grow-with-IESGN-Brochure.pdf"
import advertisement from "../images/advertisement.jpeg"
export default function AdvertisementRegistrationForm() {
  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <ToastContainer />
        <div className="row">
          <div className="col-6 fw-bold ">
            <p className="common-heading" style={{fontSize:"26px"}}>
              Grow with IESGN: Pioneering Sustainable Excellence
            </p>
          </div>
          <div className="col-6 text-end mb-4">
            <a className="btn learn-more-btn" target="_blank" href={brochure} >Download Brochure</a>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">

            <div style={{fontSize:"16px"}}>
              Alone we can do so little; together we can do so much- Helen Keller <br/> <br/>

              The <b>Indian ESG Network</b> is a leading platform dedicated to promoting <b>Environmental, Social and Governance</b> (ESG) practices through our network of sustainability professionals. Over the period many experts, professionals, advisors and companies  have joined the network and are contributing or seeking services in various aspects of ESG. <br/><br/>

              As a next step, to foster collaboration and growth for all in this space, we are launching <b>"Grow with IESGN"</b>. Through this, the Indian ESG Network is opening its social media platform to provide <b>visibility to individuals and companies </b> for their services and ESG specialisation. This will enable you to reach out to more focussed audience and share your expertise in this space. The details of packages and costs are enclosed. These have been nominally priced for the IESGN network members who have been part of our journey and contributing towards building a sustainable future for all.
            </div>

            <h4 className="mt-4">Click Here to Grow Your Impact with IESGN</h4>
            <ul>
              <li>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc043wtDPjXFgDCpUSDpI9Ag6iszpxvRkPd2idakoicCiUkjg/viewform">
                  Grow With IESGN (For Companies/Organisations)
                </a>
              </li>
              <li>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXQZMcthKlqFkCSqmiw6NPI486RKWy4bo8Ltr8PC_vLEEJ3Q/viewform">
                Grow With IESGN (For Experts)
              </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <img className="img-fluid" src={advertisement} />
          </div>
        </div>

      </div>
    </>
  )
}
